import React from "react";
import { graphql, navigate } from "gatsby";
import Layout from "../components/layout";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import {
  mainWhite,
  transHover,
  innerWidth,
  lightGrey,
  screen,
} from "../components/common/variables";
import StyledButton from "../components/styled-button";
import { formatDate, toSlug, reduceWords } from "../helpers";
import SEO from "../components/seo";
import SubBanner from "../components/banner/sub";

const Wrapper = styled.div`
  .banner {
    background: ${lightGrey};
    border-top: 22px ${mainWhite} solid;
    @media ${screen.xsmall} {
      border-bottom: 15px ${mainWhite} solid;
      border-top: 15px ${mainWhite} solid;
    }
    @media ${screen.large} {
      border-bottom: 17px ${mainWhite} solid;
      border-top: 17px ${mainWhite} solid;
    }

    .inner-wrapper {
      .featured-txt {
        .inner-txt {
          text-align: center;
          @media ${screen.small} {
            text-align: left;
          }

          .red-heading {
          }

          .capital-heading {
            margin: 12px 0 0 0;
            @media ${screen.xsmall} {
              margin: 25px 0 0 0;
            }
          }
        }
      }
    }
  }

  .blogs {
    .inner-wrapper {
      max-width: ${innerWidth};
      margin: 0 auto;
      padding: 22px 0 0 0;
      @media ${screen.xsmall} {
        padding: 100px 42px;
      }
      @media ${screen.small} {
        padding: 100px 30px;
      }
      @media ${screen.large} {
        padding: 110px 0;
      }

      .more-btn {
        text-align: center;
        @media ${screen.xsmall} {
          margin: 80px 0 0 0;
        }
      }
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      @media ${screen.xsmall} {
        margin: 0 -9px;
      }

      .each-blog {
        width: 100%;
        margin: 0 0 60px 0;
        display: flex;
        flex-direction: column;
        @media ${screen.xsmall} {
          margin: 0 9px 50px 9px;
          width: calc(50% - 18px);
        }
        @media ${screen.small} {
          width: calc(33.33% - 18px);
        }

        &__img {
          width: 100%;
          cursor: pointer;
          transition: ${transHover};
          @media ${screen.withCursor} {
            &:hover {
              opacity: 0.85;
            }
          }
        }

        &__title {
          cursor: pointer;
          font-size: 1.22rem;
          font-weight: 400;
          margin: 9px 0 0 0;
          line-height: 1.4;
          max-width: 450px;
          @media ${screen.xsmall} {
            font-size: 1.4rem;
            margin: 10px 0 0 0;
          }
          @media ${screen.large} {
            font-size: 1.5rem;
          }
        }

        &__date {
          font-size: 0.75rem;
          font-weight: 500;
          display: block;
          margin: 10px 0 0 0;
          text-transform: uppercase;
          @media ${screen.xsmall} {
            margin: 11px 0 0 0;
          }
        }

        &__excerpt {
          max-width: 430px;
          margin: 8px 0 16px 0;
        }

        .btn {
          margin: auto 0 0 0;
        }
      }
    }
  }
`;

const NewsPage = ({ data }) => {
  const { banner_heading, banner_image, title_tag, meta_description } =
    data.content.data;

  // re-order blogs by date based on Prismic
  const blogsByDate = data.blogs.edges
    .map((item) => {
      return {
        node: {
          // if edit published date is empty, use the default first publication date from Prismic
          published_date: item.node.data.edit_published_date
            ? Number(item.node.data.edit_published_date.split("-").join(""))
            : Number(
                item.node.first_publication_date
                  .slice(0, 10)
                  .split("-")
                  .join("")
              ),
          edited_date: item.node.data.edit_published_date,
          original_date: item.node.first_publication_date,
          data: item.node.data,
          id: item.node.id,
        },
      };
    })
    // sort by formatted date as a number (e.g 20201225 - 20191225)
    .sort((a, b) => b.node.published_date - a.node.published_date);

  return (
    <Layout>
      <SEO title={title_tag} description={meta_description} />
      <Wrapper>
        <SubBanner className="banner" bgUrl={banner_image.url}>
          <div className="inner-wrapper">
            <div className="featured-txt">
              <div className="inner-txt">
                <h6 className="red-heading">COMMUNITY</h6>
                <h1 className="capital-heading">{banner_heading.text}</h1>
              </div>
            </div>
          </div>
        </SubBanner>

        <section className="blogs">
          <div className="inner-wrapper">
            <ul className="blogs__list">
              {blogsByDate.map((item) => (
                <li className="each-blog" key={item.node.id}>
                  <div
                    className="each-blog__img"
                    onClick={() =>
                      navigate(`/news/${toSlug(item.node.data.title.text)}`)
                    }
                  >
                    <GatsbyImage
                      image={
                        item.node.data.featured_image.thumbnails.thumbnail
                          .gatsbyImageData
                      }
                      alt={item.node.data.featured_image.alt || "Thumbnail"}
                    />
                  </div>
                  <h2
                    className="each-blog__title"
                    onClick={() =>
                      navigate(`/news/${toSlug(item.node.data.title.text)}`)
                    }
                  >
                    {item.node.data.title.text}
                  </h2>
                  <span className="each-blog__date">
                    POSTED{" "}
                    {item.node.edited_date
                      ? formatDate(item.node.edited_date)
                      : formatDate(item.node.original_date)}
                  </span>
                  <p className="each-blog__excerpt normal-p">
                    {reduceWords(item.node.data.description.text, 23)}
                  </p>

                  <StyledButton
                    className="btn"
                    to={`/news/${toSlug(item.node.data.title.text)}`}
                  >
                    READ MORE
                  </StyledButton>
                </li>
              ))}
            </ul>
          </div>
        </section>
      </Wrapper>
    </Layout>
  );
};

export default NewsPage;

export const dataQuery = graphql`
  {
    blogs: allPrismicArticle {
      edges {
        node {
          id
          first_publication_date
          data {
            title {
              text
            }
            edit_published_date
            featured_image {
              alt
              thumbnails {
                thumbnail {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
            description {
              text
            }
          }
        }
      }
    }
    content: prismicNewsPage {
      data {
        title_tag
        meta_description
        banner_image {
          alt
          url
        }
        banner_heading {
          text
        }
      }
    }
  }
`;
